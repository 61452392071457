import Experience from "@/Experience/Experience.js";
import OfficeRoom from "@/Experience/Components/OfficeRoom.js";
import GoogleLeds from "@/Experience/Components/GoogleLeds.js";
import TopChair from "@/Experience/Components/TopChair.js";
import PCScreenA from "@/Experience/Components/PCScreenA.js";
import PCScreenB from "@/Experience/Components/PCScreenB.js";
import TVScreen from "@/Experience/Components/TVScreen.js";
import Signs from "@/Experience/Components/Signs.js";
import Instructions from "@/Experience/Components/Instructions.js";

export default class World {
  constructor(_options) {
    this.experience = new Experience();
    this.config = this.experience.config;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    this.resources.on("groupEnd", (_group) => {
      if (_group.name === "base") {
        this.setOfficeRoom();
        this.setGoogleLeds();
        this.setTopChair();
        this.setPCScreenA();
        this.setPCScreenB();
        this.setTVScreen();
        this.setSigns();
        this.setInstructions();
      }
    });
  }

  setOfficeRoom() {
    this.officeRoom = new OfficeRoom();
  }

  setGoogleLeds() {
    this.googleLeds = new GoogleLeds();
  }

  setTopChair() {
    this.topChair = new TopChair();
  }

  setPCScreenA() {
    this.screenA = new PCScreenA();
  }

  setPCScreenB() {
    this.screenB = new PCScreenB();
  }

  setTVScreen() {
    this.tvScreen = new TVScreen();
  }

  setSigns() {
    this.signs = new Signs();
  }

  setInstructions() {
    this.instructions = new Instructions();
  }

  resize() {}

  update() {
    if (this.googleLeds) this.googleLeds.update();

    if (this.topChair) this.topChair.update();

    if (this.tvScreen) this.tvScreen.update();

    if (this.screenA) this.screenA.update();

    if (this.screenB) this.screenB.update();

    if (this.signs) this.signs.update();

    if (this.instructions) this.instructions.update();
  }

  destroy() {}
}
