import * as THREE from "three";
import Experience from "@/Experience/Experience.js";

export default class RayCaster {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera;
    this.sizes = this.experience.sizes;
    this.controller = this.experience.controller;
    this.config = this.experience.config;
    this.sounds = this.experience.sounds;

    this.config.touch = this.experience.config.touch;

    // Debug
    if (this.debug) {
      this.debugFolder = this.debug.ui.addFolder("HitBox Debug");
    }

    // Setting up objects for raycaster
    this.raycaster = new THREE.Raycaster();
    this.cursorDown = new THREE.Vector2();
    this.cursor = new THREE.Vector2();
    this.hitBoxMaterial = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      wireframe: true,
    });

    // Neon signes Hitboxes
    this.signHitBox = new THREE.Group();

    if (this.config.vertical === true) {
      this.signHitBoxGeometry = new THREE.BoxGeometry(2.25, 0.8, 0.25);

      this.projectsSign = new THREE.Mesh(
        this.signHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.projectsSign.position.set(4.7, 4.9, 3.2);
      this.projectsSign.rotation.y = Math.PI / 2;

      this.homeSign = new THREE.Mesh(
        this.signHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.homeSign.position.set(4.7, 5.7, 1.9);
      this.homeSign.rotation.y = Math.PI / 2;

      this.aboutMeSign = new THREE.Mesh(
        this.signHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.aboutMeSign.position.set(4.7, 4.9, 0.6);
      this.aboutMeSign.rotation.y = Math.PI / 2;
    } else {
      this.signHitBoxGeometry = new THREE.BoxGeometry(2.25, 0.5, 0.25);

      this.projectsSign = new THREE.Mesh(
        this.signHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.projectsSign.position.set(4.7, 4.9, 3.2);
      this.projectsSign.rotation.y = Math.PI / 2;

      this.homeSign = new THREE.Mesh(
        this.signHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.homeSign.position.set(4.7, 5.6, 1.9);
      this.homeSign.rotation.y = Math.PI / 2;

      this.aboutMeSign = new THREE.Mesh(
        this.signHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.aboutMeSign.position.set(4.7, 4.9, 0.6);
      this.aboutMeSign.rotation.y = Math.PI / 2;
    }

    this.signHitBox.add(this.projectsSign, this.homeSign, this.aboutMeSign);
    this.signHitBox.visible = false;
    this.scene.add(this.signHitBox);

    // Create Project Hitboxes

    this.projectHitBoxes = new THREE.Group();
    this.projectHitBoxGeometry = new THREE.BoxGeometry(0.375, 0.55, 0.01);

    this.project1 = new THREE.Mesh(
      this.projectHitBoxGeometry,
      this.hitBoxMaterial,
    );
    this.project1.position.set(-2.32, 3.2, -4.42);
    this.project1.rotation.y = 0.08;

    this.project2 = new THREE.Mesh(
      this.projectHitBoxGeometry,
      this.hitBoxMaterial,
    );
    this.project2.position.set(-2.32 + 0.4, 3.2, -4.455);
    this.project2.rotation.y = 0.08;

    this.project3 = new THREE.Mesh(
      this.projectHitBoxGeometry,
      this.hitBoxMaterial,
    );
    this.project3.position.set(-2.32 + 0.4 * 2, 3.2, -4.49);
    this.project3.rotation.y = 0.08;

    this.project4 = new THREE.Mesh(
      this.projectHitBoxGeometry,
      this.hitBoxMaterial,
    );
    this.project4.position.set(-2.32 + 0.4 * 3, 3.2, -4.525);
    this.project4.rotation.y = 0.08;

    this.projectNavigateHitBoxGeometry = new THREE.BoxGeometry(
      0.165,
      0.09,
      0.01,
    );

    if (this.config.vertical === true) {
      this.projectBack = new THREE.Mesh(
        new THREE.BoxGeometry(0.35, 0.3, 0.01),
        this.hitBoxMaterial,
      );
      this.projectBack.position.set(-1.7, 2.55, -4.39);
      this.projectBack.rotation.y = 0.08;
    } else {
      this.projectBack = new THREE.Mesh(
        this.projectNavigateHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.projectBack.position.set(-2.8, 3.71, -4.39);
      this.projectBack.rotation.y = 0.08;
    }

    this.projectEnter = new THREE.Mesh(
      this.projectNavigateHitBoxGeometry,
      this.hitBoxMaterial,
    );
    this.projectEnter.position.set(-2.8, 2.46, -4.39);
    this.projectEnter.rotation.y = 0.08;

    this.projectHitBoxes.add(
      this.project1,
      this.project2,
      this.project3,
      this.project4,
      this.projectBack,
      this.projectEnter,
    );
    this.projectHitBoxes.visible = false;
    this.scene.add(this.projectHitBoxes);

    // Create aboutMe Hitboxes

    this.aboutMeBoxes = new THREE.Group();

    if (this.config.vertical === true) {
      this.aboutMeHitBoxGeometry = new THREE.BoxGeometry(0.4, 0.5, 0.01);

      this.aboutMeBack = new THREE.Mesh(
        this.aboutMeHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.aboutMeBack.position.set(4.2, 3.6, 1.1);
      this.aboutMeBack.rotation.y = Math.PI / 2;

      this.aboutMeScreens = new THREE.Mesh(
        this.aboutMeHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.aboutMeScreens.position.set(4.2, 3.6, 1.57);
      this.aboutMeScreens.rotation.y = Math.PI / 2;

      this.skills = new THREE.Mesh(
        this.aboutMeHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.skills.position.set(4.2, 3.6, 2.08);
      this.skills.rotation.y = Math.PI / 2;

      this.experience = new THREE.Mesh(
        this.aboutMeHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.experience.position.set(4.2, 3.6, 2.55);
      this.experience.rotation.y = Math.PI / 2;
    } else {
      this.aboutMeHitBoxGeometry = new THREE.BoxGeometry(0.4, 0.2, 0.01);

      this.aboutMeBack = new THREE.Mesh(
        new THREE.BoxGeometry(0.4, 0.2, 0.01),
        this.hitBoxMaterial,
      );
      this.aboutMeBack.position.set(4.2, 3.6, -0.08);
      this.aboutMeBack.rotation.y = Math.PI / 2;

      this.aboutMeScreens = new THREE.Mesh(
        this.aboutMeHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.aboutMeScreens.position.set(4.2, 3.6, 1.57);
      this.aboutMeScreens.rotation.y = Math.PI / 2;

      this.skills = new THREE.Mesh(
        this.aboutMeHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.skills.position.set(4.2, 3.6, 2.03);
      this.skills.rotation.y = Math.PI / 2;

      this.experience = new THREE.Mesh(
        this.aboutMeHitBoxGeometry,
        this.hitBoxMaterial,
      );
      this.experience.position.set(4.2, 3.6, 2.49);
      this.experience.rotation.y = Math.PI / 2;
    }

    this.aboutMeBoxes.add(
      this.aboutMeBack,
      this.aboutMeScreens,
      this.skills,
      this.experience,
    );
    this.aboutMeBoxes.visible = false;

    this.scene.add(this.aboutMeBoxes);

    // Create social Hitboxes

    this.socials = new THREE.Group();

    this.socialButtonGeometry = new THREE.BoxGeometry(0.13, 0.13, 0.01);

    if (this.config.vertical === true) {
      this.linkedInSocialButton = new THREE.Mesh(
        new THREE.BoxGeometry(0.4, 0.5, 0.01),
        this.hitBoxMaterial,
      );
      this.linkedInSocialButton.position.set(4.19, 1.46, 1.31);
      this.linkedInSocialButton.rotation.y = Math.PI / 2;

      this.gitHubSocialButton = new THREE.Mesh(
        new THREE.BoxGeometry(0.4, 0.5, 0.01),
        this.hitBoxMaterial,
      );
      this.gitHubSocialButton.position.set(4.19, 1.46, 2.35);
      this.gitHubSocialButton.rotation.y = Math.PI / 2;

      this.mailSocialButton = new THREE.Mesh(
        new THREE.BoxGeometry(0.55, 0.5, 0.01),
        this.hitBoxMaterial,
      );
      this.mailSocialButton.position.set(4.19, 1.46, 1.825);
      this.mailSocialButton.rotation.y = Math.PI / 2;
    } else {
      this.linkedInSocialButton = new THREE.Mesh(
        this.socialButtonGeometry,
        this.hitBoxMaterial,
      );
      this.linkedInSocialButton.position.set(4.19, 1.46, 3.095);
      this.linkedInSocialButton.rotation.y = Math.PI / 2;

      this.gitHubSocialButton = new THREE.Mesh(
        this.socialButtonGeometry,
        this.hitBoxMaterial,
      );
      this.gitHubSocialButton.position.set(4.19, 1.46, 3.27);
      this.gitHubSocialButton.rotation.y = Math.PI / 2;

      this.mailSocialButton = new THREE.Mesh(
        new THREE.BoxGeometry(0.5, 0.14, 0.01),
        this.hitBoxMaterial,
      );
      this.mailSocialButton.position.set(4.19, 1.46, 3.65);
      this.mailSocialButton.rotation.y = Math.PI / 2;
    }

    this.socials.add(
      this.linkedInSocialButton,
      this.gitHubSocialButton,
      this.mailSocialButton,
    );
    this.socials.visible = false;
    this.scene.add(this.socials);

    // Create large object hitboxes for raycaster
    this.screensHitBoxes = new THREE.Group();

    this.tvScreenHitBox = new THREE.Mesh(
      new THREE.BoxGeometry(0.15, 2.4, 4.24),
      this.hitBoxMaterial,
    );
    this.tvScreenHitBox.position.set(4.2, 2.55, 1.826);

    this.pcScreenAHitBox = new THREE.Mesh(
      new THREE.BoxGeometry(0.1, 1.4, 2.4),
      this.hitBoxMaterial,
    );
    this.pcScreenAHitBox.position.set(-1.7, 3.08, -4.535);
    this.pcScreenAHitBox.rotation.y = 1.65;

    this.pcScreenBHitBox = new THREE.Mesh(
      new THREE.BoxGeometry(0.1, 1.4, 2.4),
      this.hitBoxMaterial,
    );
    this.pcScreenBHitBox.position.set(0.72, 3.08, -4.5);
    this.pcScreenBHitBox.rotation.y = 1.465;

    this.screensHitBoxes.add(
      this.tvScreenHitBox,
      this.pcScreenAHitBox,
      this.pcScreenBHitBox,
    );
    this.screensHitBoxes.visible = false;
    this.scene.add(this.screensHitBoxes);

    // ineratcion for raycaster
    this.objectsToTest = [
      // signs
      this.projectsSign,
      this.homeSign,
      this.aboutMeSign,

      // projects
      this.project1,
      this.project2,
      this.project3,
      this.project4,
      this.projectBack,
      this.projectEnter,

      // aboutMeScreen
      this.aboutMeBack,
      this.aboutMeScreens,
      this.skills,
      this.experience,

      // creditsScreen
      this.pcScreenBHitBox,

      // socials
      this.linkedInSocialButton,
      this.gitHubSocialButton,
      this.mailSocialButton,
    ];

    // ineratcion for raycaster
    this.machinesToTest = [
      this.tvScreenHitBox,
      this.pcScreenAHitBox,
      this.pcScreenBHitBox,
      this.aboutMeBack,
    ];

    // Touch and Click configuration
    this.touchedPoints = [];

    window.addEventListener("pointerdown", (event) => {
      this.touchedPoints.push(event.pointerId);

      this.cursorXMin = Math.abs(
        ((event.clientX / this.sizes.width) * 2 - 1) * 0.9,
      );
      this.cursorXMax = Math.abs(
        ((event.clientX / this.sizes.width) * 2 - 1) * 1.1,
      );

      this.cursorYMin = Math.abs(
        ((event.clientY / this.sizes.height) * 2 - 1) * 0.9,
      );
      this.cursorYMax = Math.abs(
        ((event.clientY / this.sizes.height) * 2 - 1) * 1.1,
      );
    });

    // Click listener
    window.addEventListener("pointerup", (event) => {
      this.cursor.x = (event.clientX / this.sizes.width) * 2 - 1;
      this.cursor.y = -(event.clientY / this.sizes.height) * 2 + 1;

      this.absX = Math.abs(this.cursor.x);
      this.absY = Math.abs(this.cursor.y);

      if (
        this.touchedPoints.length === 1 &&
        this.absX > this.cursorXMin &&
        this.absX < this.cursorXMax &&
        this.absY > this.cursorYMin &&
        this.absY < this.cursorYMax
      ) {
        this.click(this.cursor);

        this.touchedPoints = [];
      } else {
        this.touchedPoints = [];
      }
    });
  }

  click(cursor) {
    this.raycaster.setFromCamera(cursor, this.camera.instance);

    //Object click listener
    this.intersectsObjects = this.raycaster.intersectObjects(
      this.objectsToTest,
    );
    if (this.intersectsObjects.length) {
      this.selectedModel = this.intersectsObjects[0].object;

      switch (this.selectedModel) {
        // Signs

        case this.projectsSign:
          this.controller.menuControls.projects();
          break;

        case this.homeSign:
          this.controller.menuControls.home();
          break;

        case this.aboutMeSign:
          this.controller.menuControls.aboutMe();
          break;

        //projects

        case this.project1:
          this.controller.projectControls.project1();
          break;

        case this.project2:
          this.controller.projectControls.project2();
          break;

        case this.project3:
          this.controller.projectControls.project3();
          break;

        case this.project4:
          this.controller.projectControls.project4();
          break;

        case this.projectBack:
          this.controller.projectControls.projectBack();
          break;

        case this.projectEnter:
          this.controller.projectControls.projectEnter();
          break;

        //aboutMe Menu

        case this.aboutMeBack:
          this.controller.aboutMeControls.aboutMeBack();
          break;
        case this.aboutMeScreens:
          this.controller.aboutMeControls.aboutMeScreens();
          break;
        case this.skills:
          this.controller.aboutMeControls.aboutMeSkills();
          break;
        case this.experience:
          this.controller.aboutMeControls.aboutMeExperience();
          break;

        // Screens

        case this.pcScreenBHitBox:
          this.controller.screenControls.creditsScreen();
          break;

        // Socials

        case this.linkedInSocialButton:
          this.controller.socialControls.linkedIn();
          break;

        case this.gitHubSocialButton:
          this.controller.socialControls.gitHub();
          break;

        case this.mailSocialButton:
          this.controller.socialControls.mail();
          break;
      }
    }

    //Object click listener
    this.intersectsObjects = this.raycaster.intersectObjects(
      this.machinesToTest,
    );
    if (this.intersectsObjects.length) {
      this.selectedModel = this.intersectsObjects[0].object;

      switch (this.selectedModel) {
        case this.tvScreenHitBox:
          this.controller.menuControls.aboutMe();
          break;
        case this.pcScreenAHitBox:
          this.controller.menuControls.projects();
          break;
        case this.pcScreenBHitBox:
          this.controller.menuControls.credits();
          break;
      }
    }
  }
}
