import * as THREE from "three";
import Experience from "@/Experience/Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from "gsap";

export default class Camera {
  constructor(_options) {
    // Options
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.targetElement = this.experience.targetElement;
    this.config = this.experience.config;
    this.time = this.experience.time;

    if (this.config.vertical === true) {
      this.aboutMeDistance = 2.6;
      this.projectsDistance = 4.6;
    } else {
      this.aboutMeDistance = 2.2;
      this.projectsDistance = 4.2;
    }

    this.setInstance();
    this.setControls();
    this.setCamAngles();
    this.setTransitions();

    if (this.debug) {
      this.debugFolder = this.debug.ui.addFolder("camera");

      this.positionDebugFolder = this.debugFolder.addFolder("cameraPosition");
      this.positionDebugFolder
        .add(this.instance.position, "x")
        .min(-40)
        .max(40)
        .step(0.1);
      this.positionDebugFolder
        .add(this.instance.position, "y")
        .min(-40)
        .max(40)
        .step(0.1);
      this.positionDebugFolder
        .add(this.instance.position, "z")
        .min(-40)
        .max(40)
        .step(0.1);

      this.targetDebugFolder = this.debugFolder.addFolder("cameraTarget");
      this.targetDebugFolder
        .add(this.controls.target, "x")
        .min(-20)
        .max(20)
        .step(0.1);
      this.targetDebugFolder
        .add(this.controls.target, "y")
        .min(-20)
        .max(20)
        .step(0.1);
      this.targetDebugFolder
        .add(this.controls.target, "z")
        .min(-20)
        .max(20)
        .step(0.1);

      this.debugFolder.add(this.controls, "enablePan");

      this.cam = false;
      this.cameraToggle = { unlockCamera: false };
      this.debugFolder.add(this.cameraToggle, "unlockCamera").onChange(() => {
        this.cam ? this.camAngle.default() : this.camAngle.unlocked();
      });
    }
  }

  setInstance() {
    // Set up
    this.instance = new THREE.PerspectiveCamera(
      25,
      this.sizes.width / this.sizes.height,
      0.1,
      150,
    );
    if (this.config.vertical === true) {
      this.instance.position.set(-35, 15, 35);
    } else {
      this.instance.position.set(-20, 15, 20);
    }

    this.instance.rotation.reorder("YXZ");

    this.scene.add(this.instance);
  }

  setControls() {
    // camera setup
    this.controls = new OrbitControls(this.instance, this.targetElement);

    // camera config
    this.controls.enableDamping = true;
    this.controls.enableRotate = true;
    this.controls.enableZoom = true;
    this.controls.enableKeys = true;
    this.controls.enablePan = false;
    this.controls.screenSpacePanning = true;

    // camera speeds
    this.controls.rotateSpeed = 0.5;
    this.controls.zoomSpeed = 0.35;
    this.controls.target.y = 2.2;

    // limits up and down
    this.controls.minPolarAngle = 0.5;
    this.controls.maxPolarAngle = Math.PI / 2 - 0.1;

    // limits left and right
    this.controls.minAzimuthAngle = -Math.PI * 0.5;
    this.controls.maxAzimuthAngle = 0;

    // limits zoom
    if (this.config.vertical === true) {
      this.controls.minDistance = 10;
      this.controls.maxDistance = 60;
    } else {
      this.controls.minDistance = 10;
      this.controls.maxDistance = 40;
    }
  }

  setCamAngles() {
    this.camAngle = {};

    this.camAngle.unlocked = () => {
      if (this.config.vertical === true) {
        this.controls.minDistance = 0;
        this.controls.maxDistance = 60;
      } else {
        this.controls.minDistance = 0;
        this.controls.maxDistance = 30;
      }
      this.controls.minAzimuthAngle = 0;
      this.controls.maxAzimuthAngle = Math.PI * 1.999;
      this.controls.minPolarAngle = 0;
      this.controls.maxPolarAngle = Math.PI;
      this.cam = true;
    };

    this.camAngle.default = () => {
      if (this.config.vertical === true) {
        this.controls.minDistance = 10;
        this.controls.maxDistance = 60;
      } else {
        this.controls.minDistance = 10;
        this.controls.maxDistance = 30;
      }
      this.controls.minAzimuthAngle = -Math.PI * 0.5;
      this.controls.maxAzimuthAngle = 0;
      this.controls.minPolarAngle = 0.5;
      this.controls.maxPolarAngle = Math.PI / 2 - 0.1;
      this.cam = false;
    };

    this.camAngle.pcScreenA = () => {
      if (this.config.vertical === true) {
        this.controls.minDistance = 6;
        this.controls.maxDistance = 12;
      } else {
        this.controls.minDistance = 4.5;
        this.controls.maxDistance = 8;
      }

      this.controls.minAzimuthAngle = -(Math.PI * 0.1); //left
      this.controls.maxAzimuthAngle = Math.PI * 0.1; //right
      this.controls.minPolarAngle = Math.PI * 0.4;
      this.controls.maxPolarAngle = Math.PI * 0.53;
    };

    this.camAngle.tvScreen = () => {
      this.controls.minDistance = 23.5;
      this.controls.maxDistance = 32;
      this.controls.minAzimuthAngle = 0;
      this.controls.maxAzimuthAngle = Math.PI * 1.999;
      this.controls.minPolarAngle = Math.PI * 0.3;
      this.controls.maxPolarAngle = Math.PI * 0.65;
    };

    this.camAngle.pcScreenB = () => {
      if (this.config.vertical === true) {
        this.controls.minDistance = 6;
        this.controls.maxDistance = 12;
      } else {
        this.controls.minDistance = 4.5;
        this.controls.maxDistance = 8;
      }
      this.controls.minAzimuthAngle = -(Math.PI * 0.2); //left
      this.controls.maxAzimuthAngle = Math.PI * 0.2; //right
      this.controls.minPolarAngle = Math.PI * 0.3;
      this.controls.maxPolarAngle = Math.PI * 0.65;
    };
  }

  setTransitions() {
    this.transitions = {};

    this.transitions.pcScreenA = async (duration) => {
      this.controls.enableRotate = false;
      this.controls.enableZoom = false;

      if (this.config.vertical === true) {
        gsap.to(this.instance.position, {
          duration: duration,
          ease: "power1.inOut",
          x: 0.5,
          y: 4.4,
          z: 6.1,
        }); //0, 3.75, 0
        gsap.to(this.controls.target, {
          duration: duration,
          ease: "power1.inOut",
          x: -1.7,
          y: 3.08,
          z: -4.535,
        });
      } else {
        gsap.to(this.instance.position, {
          duration: duration,
          ease: "power1.inOut",
          x: -2,
          y: 3.75,
          z: 0,
        });
        gsap.to(this.controls.target, {
          duration: duration,
          ease: "power1.inOut",
          x: -1.25,
          y: 3.08,
          z: -4.535,
        });
      }

      await this.sleep(1500);
      // this.controls.enableRotate = true
      this.controls.enableZoom = true;
    };

    this.transitions.default = async (duration) => {
      this.controls.enableRotate = false;
      this.controls.enableZoom = false;

      if (this.config.vertical === true) {
        gsap.to(this.instance.position, {
          duration: duration,
          ease: "power1.inOut",
          x: -35,
          y: 15,
          z: 35,
        });
      } else {
        gsap.to(this.instance.position, {
          duration: duration,
          ease: "power1.inOut",
          x: -20,
          y: 15,
          z: 20,
        });
      }

      gsap.to(this.controls.target, {
        duration: duration,
        ease: "power1.inOut",
        x: 0,
        y: 2.2,
        z: 0,
      });

      await this.sleep(1500);
      this.controls.enableRotate = true;
      this.controls.enableZoom = true;
    };

    this.transitions.home = async (duration) => {
      this.controls.enableRotate = false;
      this.controls.enableZoom = false;

      if (this.config.vertical === true) {
        gsap.to(this.instance.position, {
          duration: duration,
          ease: "power1.inOut",
          x: -35,
          y: 15,
          z: 35,
        });
      } else {
        gsap.to(this.instance.position, {
          duration: duration,
          ease: "power1.inOut",
          x: -20,
          y: 15,
          z: 20,
        });
      }

      await this.sleep(1500);
      this.controls.enableRotate = true;
      this.controls.enableZoom = true;
    };

    this.transitions.tvScreen = async (duration) => {
      this.controls.enableRotate = false;
      this.controls.enableZoom = false;

      gsap.to(this.instance.position, {
        duration: duration,
        ease: "power1.inOut",
        x: -7,
        y: 3,
        z: 1.8,
      });
      gsap.to(this.controls.target, {
        duration: duration,
        ease: "power1.inOut",
        x: 20,
        y: 0.8,
        z: 1.8,
      });

      await this.sleep(1500);
      // this.controls.enableRotate = true
      this.controls.enableZoom = true;
    };

    this.transitions.pcScreenB = async (duration) => {
      this.controls.enableRotate = false;
      this.controls.enableZoom = false;

      if (this.config.vertical === true) {
        gsap.to(this.instance.position, {
          duration: duration,
          ease: "power1.inOut",
          x: -4,
          y: 4.75,
          z: 3.5,
        });
        gsap.to(this.controls.target, {
          duration: duration,
          ease: "power1.inOut",
          x: 0.72,
          y: 3.08,
          z: -4.5,
        });
      } else {
        gsap.to(this.instance.position, {
          duration: duration,
          ease: "power1.inOut",
          x: -2,
          y: 3.75,
          z: 0,
        });
        gsap.to(this.controls.target, {
          duration: duration,
          ease: "power1.inOut",
          x: 0.72,
          y: 3.08,
          z: -4.5,
        });
      }

      await this.sleep(1500);
      // this.controls.enableRotate = true
      this.controls.enableZoom = true;
    };
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    this.controls.update();
  }
}
