import Experience from "@/Experience/Experience.js";
import { Howl, Howler } from "howler";

import bloop from "static/sounds/bloop.mp3";
import click from "static/sounds/click.mp3";
import whoosh from "static/sounds/whoosh.mp3";

export default class Sounds {
  constructor() {
    this.experience = new Experience();

    this.bloop = new Howl({
      src: [bloop],
      volume: 0.3,
    });

    this.click = new Howl({
      src: [click],
      volume: 0.3,
    });

    this.whoosh = new Howl({
      src: [whoosh],
      volume: 0.6,
    });

    this.setMute();
  }

  setMute() {
    // Set up
    this.muted = typeof this.debug !== "undefined";
    Howler.mute(this.muted);

    // M Key
    window.addEventListener("keydown", (_event) => {
      if (_event.key === "m") {
        this.muted = !this.muted;
        Howler.mute(this.muted);
      }
    });

    // Tab focus / blur
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        Howler.mute(true);
      } else {
        Howler.mute(this.muted);
      }
    });

    // Debug
    if (this.debug) {
      this.debugFolder
        .add(this, "muted")
        .listen()
        .onChange(() => {
          Howler.mute(this.muted);
        });
    }
  }

  playBloop() {
    this.bloop.play();
  }

  playClick() {
    this.click.play();
  }

  playWhoosh() {
    this.whoosh.play();
  }
}
