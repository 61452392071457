import * as THREE from "three";
import Experience from "@/Experience/Experience.js";
import warzoneBig from "static/assets/warzoneBig.mp4";

export default class PCScreenB {
  constructor() {
    this.experience = new Experience();
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.world = this.experience.world;
    this.materials = this.experience.materials;
    this.time = this.experience.time;

    this.setModel();
  }

  setModel() {
    this.model = {};

    // Element
    this.model.element = document.createElement("video");
    this.model.element.muted = true;
    this.model.element.loop = true;
    this.model.element.controls = true;
    this.model.element.playsInline = true;
    this.model.element.autoplay = true;
    this.model.element.src = warzoneBig;

    window.addEventListener("pointerdown", () => {
      this.model.element.volume = 0.035;
      this.model.element.muted = false;
      this.model.element.play();
    });

    // Video texture
    this.model.texture = new THREE.VideoTexture(this.model.element);
    this.model.texture.encoding = THREE.sRGBEncoding;

    // Video material
    this.model.material = new THREE.MeshBasicMaterial({
      map: this.model.texture,
    });

    this.model.mesh = this.resources.items.pcScreenBModel.scene.children[0];
    this.model.mesh.material = this.model.material;
    this.scene.add(this.model.mesh);
  }

  update() {}
}
