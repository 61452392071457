import * as THREE from "three";

import Experience from "@/Experience/Experience.js";

export default class OfficeRoom {
  constructor() {
    this.experience = new Experience();
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.world = this.experience.world;

    this.setRoom();
  }

  setRoom() {
    this.room = {};
    this.room.model = this.resources.items.roomModel.scene;

    this.room.texture = this.resources.items.bakedTexture;
    this.room.texture.encoding = THREE.sRGBEncoding;
    this.room.texture.flipY = false;

    this.room.material = new THREE.MeshBasicMaterial({
      map: this.room.texture,
    });

    this.room.model.traverse((_child) => {
      if (_child instanceof THREE.Mesh) {
        _child.material = this.room.material;
      }
    });

    this.scene.add(this.room.model);
  }

  update() {}
}
