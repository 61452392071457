import Experience from "@/Experience/Experience.js";

export default class Signs {
  constructor() {
    this.experience = new Experience();
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.world = this.experience.world;
    this.materials = this.experience.materials;

    this.resource = this.resources.items.signsModel;

    this.setModel();
  }

  setModel() {
    this.model = this.resource.scene;

    this.projectsNeon = this.model.children.find(
      (child) => child.name === "projectsText",
    );
    this.projectsNeon.material = this.materials.neonBlueMaterial;

    this.aboutMeNeon = this.model.children.find(
      (child) => child.name === "aboutMeText",
    );
    this.aboutMeNeon.material = this.materials.neonPinkMaterial;

    this.homeNeon = this.model.children.find(
      (child) => child.name === "homeText",
    );
    this.homeNeon.material = this.materials.neonGreenMaterial;

    this.scene.add(this.model);
  }

  update() {}
}
